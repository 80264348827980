/**
 * Filter Schema of Token
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {ITokenCollectionResourcesHolder} from '@/model/collection/TokenCollection'
import {Token} from '@/model/resource/Token'

/* TODO: review generated schema */
export class FilterTokenSchema extends DefaultSchema
  implements ITokenCollectionResourcesHolder {
  readonly name = 'FilterToken'

  readonly fieldSet: FieldSet<Token> = {}
}
