















































































































import {Component, Prop, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterToken from '@/components/filters/FilterToken.vue'
import {Token} from '@/model/resource/Token'
import {TokenCollection} from '@/model/collection/TokenCollection'
import {ListTokenSchema} from '@/schema/resource/Token/ListTokenSchema'
import {ExportTokenSchema} from '@/schema/resource/Token/ExportTokenSchema'

@Component({
  components: {FilterToggle, FilterToken},
})
export default class ListTokenView extends Mixins(MixinRouteMatch) {
  schema = new ListTokenSchema()
  collection = new TokenCollection()
  filterOpen = false

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: Token) {
    this.$nav.pushByName('editToken', item.$id)
  }

  async removeItem(item: Token) {
    await this.$dialog.remove(item)
    await item.removeToken()
    await this.collection.queryAsPage()
  }

  async downloadXlsx() {
    const {ascending, orderBy, page, limit, ...params} = this.collection.params

    const coll = new TokenCollection().clearFilters().addFilter(params)

    await coll.listExportToken()
    this.$xlsx.downloadFromSchema(coll.items, new ExportTokenSchema())
  }
}
